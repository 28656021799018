import $ from 'jquery';
import ToggleVisibility from '../utilities/toggle-visibility';
import breakpointTokens from '../compiled/{{ BH_BRAND }}/breakpoints.json';
const breakpoints = breakpointTokens.size.breakpoint;

/**
 * Supports the Collapse CSS pattern by syncing relevant ARIA attributes to the
 * current element state. Designed to be used as a jQuery plugin.
 *
 * If the component includes a `data-toggle-icon="SELECTOR"` attribute, then
 * this will also toggle the active class for contained elements matching
 * `SELECTOR` on every update.
 */
class Collapse {
  /**
   * Instantiate Collapse on an existing element.
   *
   * @param {Element} element
   */
  constructor(element) {
    this.$element = $(element);
    this.$content = this.$element.children('.Collapse-content');
    this.$button = this.$element.find('[aria-expanded]');
    this.$button.on('click.jax.collapse', () => this._toggle());
    this.$buttonIcon = this.$element.find(this.$element.data('toggle-icon'));
    this.$buttonIcon.addClass('Collapse-icon');
    this.$footnote = this.$element.find('.Collapse-footnote');

    if (this.$footnote) {
      this.footnoteTransitioner = new ToggleVisibility(this.$footnote);
    }
  }

  /**
   * Hide Content
   */
  _hide() {
    this.$element.removeClass('is-open');
    this.$content.attr('hidden', true);
    this.$button.attr('aria-expanded', false);
    this.$buttonIcon.removeClass('is-on');

    if (this.$footnote) {
      this.footnoteTransitioner.show();
    }
  }

  /**
   * Show Content
   */
  _show() {
    this.$element.addClass('is-open');
    this.$content.attr('hidden', false);
    this.$button.attr('aria-expanded', true);
    this.$buttonIcon.addClass('is-on');

    if (this.$footnote) {
      this.footnoteTransitioner.hide();
    }
  }

  /**
   * Disable Collapse button
   */
  _disable() {
    this.$element.addClass('is-disabled');
    this.$button.attr('disabled', true);
    this.$buttonIcon.attr('hidden', true);
  }

  /**
   * Enable Collapse button
   */
  _enable() {
    this.$element.removeClass('is-disabled');
    this.$button.attr('disabled', false);
    this.$buttonIcon.attr('hidden', false);
  }

  /**
   * Toggle the open state
   */
  _toggle() {
    if (this.$button.attr('aria-expanded') === 'true') {
      this._hide();
    } else {
      this._show();
    }
  }

  /**
   * Respond to Breakpoint Changes
   *
   * When a media query crosses a breakpoint, if the element has the appropriate
   * responsive modifier class, then show or hide the content. Intended to be
   * called from media query listeners.
   *
   * NOTE: Breakpoints must be a regex becuase IE returns a different version.
   * @param {string} breakpoint
   * @param {boolean} matches
   * @see http://javascriptkit.com/javatutors/matchmediamultiple.shtml
   */
  respond(breakpoint, matches) {
    const mdBreakpointRegEx = new RegExp(
      `\\(min-width:\\s*${breakpoints.md}\\)`
    );
    const lgBreakpointRegEx = new RegExp(
      `\\(min-width:\\s*${breakpoints.lg}\\)`
    );

    if (
      this.$element.data('disable-md') &&
      mdBreakpointRegEx.test(breakpoint)
    ) {
      if (matches) {
        this._show();
        this._disable();
      } else {
        this._hide();
        this._enable();
      }
    }

    if (
      this.$element.data('disable-lg') &&
      lgBreakpointRegEx.test(breakpoint)
    ) {
      if (matches) {
        this._show();
        this._disable();
      } else {
        this._hide();
        this._enable();
      }
    }
  }

  /**
   * Interface for using this class as a jQuery plugin.
   * @param {string} config
   * @param {...any} args
   * @returns {jQuery}
   */
  static _jQueryInterface(config, ...args) {
    return this.each(function () {
      const $this = $(this);
      let data = $this.data('jax.collapse');

      if (!data) {
        data = new Collapse(this);
        $this.data('jax.collapse', data);
      }

      if (typeof config === 'string') {
        if (data[config] === undefined) {
          throw new Error(`No method named "${config}"`);
        }

        data[config](...args);
      }
    });
  }
}

$.fn.collapse = Collapse._jQueryInterface;
$.fn.collapse.Constructor = Collapse;

export default Collapse;
