import $ from 'jquery';
import lozad from 'lozad';
import 'focus-visible';
import './utilities/toggle-visibility.js';
import { components } from './compiled/{{ BH_BRAND }}/components.json';
import breakpointTokens from './compiled/{{ BH_BRAND }}/breakpoints.json';
const breakpoints = breakpointTokens.size.breakpoint;

$(() => {
  if (components.announcement) {
    import('./components/dismissable.js').then(() => {
      $('.js-Dismissable').dismissable();
    });
  }

  if (components.collapse) {
    import('./components/collapse.js').then(() => {
      $('.js-Collapse').collapse();
    });
  }

  if (components.modal) {
    import('./components/modal.js').then((modals) => {
      modals.create('.js-Modal');
    });
  }

  if (components.input) {
    import('hideshowpassword').then(() => {
      $('.js-PasswordToggle').hidePassword('focus');
    });

    /**
     * Auto-select input field contents on focus.
     *
     * This technique is hacky but works in iOS and modern browsers without
     * disabling adjustment of the selection.
     *
     * We use event delegation for this event so that it will work even for
     * elements that are added after page load.
     *
     * @see https://stackoverflow.com/a/22102081/5175805
     * @see https://stackoverflow.com/a/29643207
     */
    $(document).on('focus.jax.selectOnFocus', '.js-SelectOnFocus', (event) => {
      setTimeout(() => event.currentTarget.setSelectionRange(0, 9999), 0);
    });
  }

  if (components['input-group']) {
    $('.js-InputGroup').each(function () {
      const group = $(this);
      const inputs = group.find('input[type!="submit"]');

      if (inputs.length === 1) {
        inputs
          .on('focus.jax.inputGroup', () => {
            group.addClass('is-focused');
          })
          .on('blur.jax.inputGroup', () => {
            group.removeClass('is-focused');
          });
      }
    });
  }

  if (components['input-reveal']) {
    import('./components/input-reveal.js').then(() => {
      $('.js-InputReveal').inputReveal();
    });
  }

  if (components.toggle) {
    import('./components/toggle-delegate.js').then(() => {
      $('.js-Toggle-delegate').toggleDelegate();
    });
  }

  if (components['toggle-step']) {
    import('./components/toggle-steps.js').then(() => {
      $('.js-ToggleSteps').toggleSteps();
    });
  }

  /**
   * Trigger Breakpoint Change Actions
   *
   * This function is called whenever a defined media query matches.
   * Add any actions that should happen on breakpoint changes.
   *
   * @param {MediaQueryList} mq
   */
  const triggerBreakpointChange = (mq) => {
    // This is the only responsive action right now, but more could live here.
    if (components.collapse) {
      $('.js-Collapse').collapse('respond', mq.media, mq.matches);
    }
  };

  /**
   * Media Queries
   *
   * Useful for responsive components that react to breakpoint changes.
   *
   * Add a listener to media queries to trigger a function whenever the browser
   * says the media query's `matches` status changes.
   */
  const mqMedium = window.matchMedia(`(min-width: ${breakpoints.md})`);
  mqMedium.addListener(triggerBreakpointChange);
  triggerBreakpointChange(mqMedium);

  const mqLarge = window.matchMedia(`(min-width: ${breakpoints.lg})`);
  mqLarge.addListener(triggerBreakpointChange);
  triggerBreakpointChange(mqLarge);

  /**
   * Lazy loads elements with default selector of `.lozad`
   */
  const observer = lozad();
  observer.observe();
});
