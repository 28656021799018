import Cookies from 'js-cookie';
import $ from 'jquery';

/**
 * Supports the Announcement component by adding behavior to the dismiss button
 *
 * This class expects the component to include a `data-dismiss-days="#"` attribute
 * that will determine the expiration of the dismiss cookie. It also expects a
 * `data-dismiss-uid` attribute that .NET will use to link the cookie with the entry
 */
class Dismissable {
  /**
   * Instantiate DismissableAnnouncement on an existing element.
   *
   * @param {Element} element
   */
  constructor(element) {
    this.element = element;
    this.uid = this.element.dataset.dismissableUid;
    const dismissDays = this.element.dataset.dismissableDays;
    this.dismissDays = Number.parseInt(dismissDays || 1, 10);
    this.button = this.element.querySelector('.js-Dismissable-button');
    this.button.addEventListener('click', () => this.dismiss());
  }

  /**
   * Handler for the dismiss click event.
   */
  dismiss() {
    this.setCookie();
    this.element.setAttribute('hidden', true);
  }

  /**
   * Set a cookie to persist the dismissed announcement.
   *
   * This sets a unique cookie for each announcement. We are utilizing the cookie’s expiration
   * to “un-dissmiss” announcements when it expires. If we were to store them all in one cookie We’d
   * first have to read existing cookies, append the new cookie, as well as an expiration timestamp.
   * Then the .NET folks would have to parse this cookie and check all the expiration dates before
   * deciding which announcements to render, etc. With individual cookies, we just drop the cookie
   * (when the announcement gets dismissed) and the only thing .NET needs to care about is whether
   * it finds the expected cookie. If yes, skip that announcement, otherwise show it.
   */
  setCookie() {
    // Can't persist the dismissal without a uid to identify it
    if (!this.uid) {
      return;
    }

    Cookies.set(`dismissed-${this.uid}`, '1', { expires: this.dismissDays });
  }

  static _jQueryInterface(config) {
    return this.each(function () {
      const $this = $(this);
      let data = $this.data('jax.dismissable');

      if (!data) {
        data = new Dismissable(this);
        $(this).data('jax.dismissable', data);
      }
    });
  }
}

$.fn.dismissable = Dismissable._jQueryInterface;
$.fn.dismissable.Constructor = Dismissable;

export default Dismissable;
