import A11yDialog from 'a11y-dialog';
import scrollLock from 'scroll-lock';

/**
 * Create Modals
 *
 * Uses the a11y-dialog script to create Modals.
 *
 * @param {string} selector - the selector used to identify Modals
 * @param {NodeList|Element|string} hideTargets - a set of elements to hide
 * @see https://github.com/edenspiekermann/a11y-dialog#javascript-instantiation
 */
export const create = (selector, hideTargets) => {
  const modalElements = document.querySelectorAll(selector);

  for (const el of modalElements) {
    // Instantiate a new A11yDialog module for each Modal element
    const modal = new A11yDialog(el, hideTargets);

    modal.on('show', (modalEl, event) => {
      // Disable scrolling the page content in the background
      scrollLock.disablePageScroll(modalEl);

      modalEl.dispatchEvent(
        // eslint-disable-next-line no-undef
        new CustomEvent('modalshow', { bubbles: true })
      );
    });

    modal.on('hide', (modalEl, event) => {
      // Re-enable scrolling
      scrollLock.enablePageScroll(modalEl);

      modalEl.dispatchEvent(
        // eslint-disable-next-line no-undef
        new CustomEvent('modalhide', { bubbles: true })
      );
    });

    modal.on('destroy', (modalEl) => {
      // Re-enable scrolling if it's disabled
      scrollLock.enablePageScroll(modalEl);

      modalEl.dispatchEvent(
        // eslint-disable-next-line no-undef
        new CustomEvent('modaldestroy', { bubbles: true })
      );
    });
  }
};
