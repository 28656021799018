import { getFirstItem } from './get-first-item';

/**
 * Transition-Aware Show & Hide Element
 *
 * A custom set of functions to handle the fading in and out of elements.
 * This depends on the targeted components having support for the `is-shown`
 * class and the `hidden` attribute. Currently this plugin works with the
 * `Overlay`, `StickyBar`, and `MegaMenu` components. This implementation
 * performs better than jQuery's built in animation methods since it uses CSS
 * transitions instead of manually setting CSS for each keyframe.
 */

/**
 * Transition End Handler
 *
 * Event handler for the `transitionend` event. Sets the `hidden` attribute
 * and removes the event listener now that the animation is complete.
 *
 * @param {Event} event
 */
const transitionEndHandler = (event) => {
  // Ensure the event didn't bubble up from a child element
  if (event.target !== event.currentTarget) return;

  const element = event.currentTarget;

  // Now we know animation has completed, set the `hidden` attribute.
  element.setAttribute('hidden', '');

  // Remove our event listener.
  element.removeEventListener('transitionend', transitionEndHandler);
};

/**
 * Show Element
 *
 * Allows animation by removing the `hidden` attribute, triggering a browser
 * repaint, and then adding the `is-shown` class.
 *
 * @param {jQuery|NodeList|HTMLCollection|Element} selection - a collection or single element
 */
export const showElement = (selection) => {
  // If this is a jQuery collection, convert to a single element.
  const element = getFirstItem(selection);

  // If there's no element, abort early
  if (!element) return;

  // Begin animation.
  // (The specific transition depends on the targeted component's CSS)
  element.removeAttribute('hidden');

  // Force a browser re-paint so the browser will realize the element is no
  // longer `hidden` and allow transitions.
  // eslint-disable-next-line no-unused-expressions
  element.offsetWidth;

  // Complete animation.
  element.classList.add('is-shown');

  // Remove any lingering event listeners.
  element.removeEventListener('transitionend', transitionEndHandler);
};

/**
 * Hide Element
 *
 * Allows animation by removing the `is-shown` class, setting an event listener
 * for the `transitionend` event, and then adding the `hidden` attribute.
 *
 * @param {jQuery|NodeList|HTMLCollection|Element} selection - a collection or single element
 */
export const hideElement = (selection) => {
  // If this is a jQuery collection, convert to a single element.
  const element = getFirstItem(selection);

  // If there's no element, abort early
  if (!element) return;

  // Begin animation.
  // (The specific transition depends on the targeted component's CSS)
  element.classList.remove('is-shown');

  // Complete animation.
  if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
    // If user prefers reduced motion then we disable the transition in CSS, so
    // `transitionend` will never fire. In that case we add `hidden` immediately.
    element.setAttribute('hidden', '');
  } else {
    // If not, then we set a listener for the `transitionend` event.
    element.addEventListener('transitionend', transitionEndHandler);
  }
};
